<template>
  <v-card class="pa-5">
    <v-card-title  class="text-h5 primary--text">
      Imagens
    </v-card-title>
    <v-container fluid>
      <v-item-group
        v-model="selected"
      >
        <v-row>
          <v-col
            v-for="(item, i) in images"
            :key="i"
            cols="12"
            md="2"
          >
            <v-item v-slot="{ }">
              <v-img
                :src="`https://encode-index.s3.amazonaws.com/${item.filename}`"
                height="150"
                class="text-right pa-2"
                @mouseover="selected = i"
                @mouseleave="selected = null"
              >
                <transition name="fade-slide">
                  <v-btn
                    v-if="item.highlighted || selected === i"
                    icon
                    dark
                    absolute
                    top
                    right
                    class="primary"
                    @click="toggle(item, i)"
                  >
                    <v-icon>
                      {{ item.highlighted === 1 ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </v-btn>
                </transition>
                <transition name="fade-slide-left">
                  <v-btn
                    v-if="selected === i"
                    icon
                    dark
                    absolute
                    bottom
                    left
                    class="error"
                    @click="deleteImage(item)"
                  >
                    <v-icon>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </transition>
              </v-img>
            </v-item>
          </v-col>
          <v-col cols="12" md="2">
            <v-sheet
              @click="onButtonClick"
              rounded
              height="150"
              class="pointer primary d-flex align-center justify-center text-center"
            >
              <v-progress-circular
                v-if="isLoading"
                indeterminate
                color="white"
              />
              <v-icon v-else color="white" large>
                mdi-plus
              </v-icon>
            </v-sheet>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              multiple
              @change="onFileChanged"
            />
          </v-col>
        </v-row>
      </v-item-group>
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="$emit('close')">
        Fechar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      isLoading: Boolean,
      images: Array
    },
    data: () => ({
      selected: null,
    }),
    methods: {
      toggle(item, i) {
        this.selected = i
        this.$emit('setHighlighted', item)
      },
      onButtonClick() {
        this.$refs.uploader.click()
      },
      onFileChanged(e) {
        this.$emit('upload', e.target.files)
      },
      deleteImage(item) {
        this.$emit('delete', item)
      }
    },
  }
</script>

<style>
  .pointer {
    cursor: pointer;
  }
  .fade-slide-enter,
  .fade-slide-leave-to {
    opacity: 0;
    transform: translate(20px, -20px);
  }
  .fade-slide-enter-active,
  .fade-slide-leave-active {
    transition: all .2s;
  }
  .fade-slide-enter-to,
  .fade-slide-leave {
    opacity: 1;
    transform: translate(0, 0);
  }
  
  .fade-slide-left-enter,
  .fade-slide-left-leave-to {
    opacity: 0;
    transform: translate(-20px, 20px);
  }
  .fade-slide-left-enter-active,
  .fade-slide-left-leave-active {
    transition: all .2s;
  }
  .fade-slide-left-enter-to,
  .fade-slide-left-leave {
    opacity: 1;
    transform: translate(0, 0);
  }
</style>